//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Icon } from "../../Media/Icon/002.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Kilogram, Piece, Package, Photo, Target } = this.props
        //Element
        const Source  = "https://media.ersahgida.com.tr/product/"
        //Element
        const Image   = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-4 pb-3 m-0 bg-white p3y28f" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 qc351z" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle a9s780" },
                    //Element
                    React.createElement( "span", { className: "float-start w-auto position-relative z3hs52" },
                        //Element
                        "E"
                    )
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 r2515h" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "button", { className: "float-start w-auto p-0 m-0 bg-white border-0 v2kh82", type: "button" },
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-4 ps-0 pe-0 pt-2 m-0 bg-white o52h8k" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mt-3 h91nt5" },
                            //Element
                            React.createElement( "img", { alt: Title, title: Title, src: Image, width: "100", height: "auto" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 align-items-center p-0 m-0 r09766" },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 t439d1" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 lh-1 o16x2n" },
                                            //Element
                                            "Ürün KG"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 t439d1" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-end lh-1 p4642q" },
                                            //Element
                                            Kilogram
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 t439d1" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 lh-1 o16x2n" },
                                            //Element
                                            "Koli içi Adedi"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 t439d1" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-end lh-1 p4642q" },
                                            //Element
                                            Piece
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 t439d1" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 lh-1 o16x2n" },
                                            //Element
                                            "Koli KG"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 t439d1" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-end lh-1 p4642q" },
                                            //Element
                                            Package
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-3 gq17c3" },
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 c082cb" },
                    //Element
                    "Telif Hakkı © 2024 Erşah Gıda Tüm Hakları Saklıdır."
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    Kilogram : propTypes.string.isRequired,
    Piece    : propTypes.string.isRequired,
    Package  : propTypes.string.isRequired,
    Photo    : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired
}
//Element
export default Default;