//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Facebook } from "../../Media/Social/Facebook.svg";
//Install
import { ReactComponent as Twitter } from "../../Media/Social/Twitter.svg";
//Install
import { ReactComponent as Instagram } from "../../Media/Social/Instagram.svg";
//Install
import { ReactComponent as Youtube } from "../../Media/Social/Youtube.svg";
//Install
import { ReactComponent as Linkedin } from "../../Media/Social/Linkedin.svg";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-between align-items-center p-0 pb-5 m-0 mt-5 k8f79f" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-center align-items-center position-relative", href: "/" },
                    //Element
                    React.createElement( Facebook, {} ),
                    //Element
                    React.createElement( "strong", { className: "d-inline-block p-0 m-0 mt-4 lh-1" }, "80" )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-center align-items-center position-relative", href: "/" },
                    //Element
                    React.createElement( Twitter, {} ),
                    //Element
                    React.createElement( "strong", { className: "d-inline-block p-0 m-0 mt-4 lh-1" }, "30" )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-center align-items-center position-relative", href: "/" },
                    //Element
                    React.createElement( Instagram, {} ),
                    //Element
                    React.createElement( "strong", { className: "d-inline-block p-0 m-0 mt-4 lh-1" }, "80" )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-center align-items-center position-relative", href: "https://www.youtube.com/@ersahgida" },
                    //Element
                    React.createElement( Youtube, {} ),
                    //Element
                    React.createElement( "strong", { className: "d-inline-block p-0 m-0 mt-4 lh-1" }, "30" )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto flex-column justify-content-center align-items-center position-relative", href: "/" },
                    //Element
                    React.createElement( Linkedin, {} ),
                    //Element
                    React.createElement( "strong", { className: "d-inline-block p-0 m-0 mt-4 lh-1" }, "10" )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Social;