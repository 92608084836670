//Install
import React, { Component } from "react";

//Element
class Logo extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Icon = "https://media.ersahgida.com.tr/brand/006.png"
        //Element
        const Content = React.createElement( "button", { id: "Web_NV061S", name: "Web_NV061S", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 z40r04", type: "button", onClick: this.Home },
            //Element
            React.createElement( "img", { alt: "Ersahgida.com.tr", title: "Ersahgida.com.tr", src: Icon, width: "125", height: "auto" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Logo;