//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Media from "../../Content/Media";
//Install
import Headline from "../../Content/Headline";

//Element
class Default extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Erşah Gıda : Markalar - Ersahgida.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = ""
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 nf9678" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "img", { alt: "Oret", title: "Oret", src: "https://media.ersahgida.com.tr/brand/010.png", width: "145", height: "auto" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Media, { ID: "0", Title: "", Video: "007.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 text-center g69l8n" },
                                    //Element
                                    "Lezzetin ",
                                    //Element
                                    React.createElement( "strong", {}, "Kalbinden" ),
                                    //Element
                                    " Gelenler"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 text-center bb198o" },
                                    //Element
                                    "Özenle hazırlanan, eşsiz tatlarla dolu yemeklerimizle damağınıza",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "unutulmaz bir şölen yaşatıyoruz. Kaliteden ödün vermeden,",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "lezzetli ve özgün seçenekler sunuyor."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 text-center bb198o", href: "/markalar" },
                                    //Element
                                    React.createElement( "u", {},
                                        //Element
                                        "Hemen Bakın"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "img", { alt: "Şahköy", title: "Şahköy", src: "https://media.ersahgida.com.tr/brand/008.png", width: "200", height: "137" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Media, { ID: "0", Title: "", Video: "006.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 text-center g69l8n" },
                                    //Element
                                    "Protein ve Kolajenle Dolu ",
                                    //Element
                                    React.createElement( "strong", {}, "#GezenTavuk" )
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 text-center bb198o" },
                                    //Element
                                    "Doğadan özenle seçilen kıymetli besinlerle beslenen Tavuklar,",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "sağlığınıza katkı sağlayan lezzetiyle sofralarınızın",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "vazgeçilmez bir parçası olacak."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 text-center bb198o", href: "/markalar" },
                                    //Element
                                    React.createElement( "u", {},
                                        //Element
                                        "Hemen Bakın"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "img", { alt: "Zeyno'nun Çiftliği", title: "Zeyno'nun Çiftliği", src: "https://media.ersahgida.com.tr/brand/007.png", width: "200", height: "135" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Media, { ID: "0", Title: "", Video: "005.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 text-center g69l8n" },
                                    //Element
                                    "Ustaların Tercihi: Pizza ⅋ Künefe ",
                                    //Element
                                    React.createElement( "strong", {}, "#Peyniri" )
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 text-center bb198o" },
                                    //Element
                                    "Yapılan her pizza, künefe ve pide herkesin damak zevkine hitap eden lezzetlerle dolu olacak. Sizde emeğinizin karşılığını",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "alarak, kazancınızın keyfini çıkarın."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 text-center bb198o", href: "/markalar" },
                                    //Element
                                    React.createElement( "u", {},
                                        //Element
                                        "Hemen Bakın"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;