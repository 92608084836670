//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../Media/Icon/006.svg";

//Element
class Call extends Component {
    //Element
    Target = () => {
        //Element
        window.location.href = "tel:+905511196933"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 m6v56f" },
            //Element
            React.createElement( "button", { id: "Web_Z92D3O", name: "Web_Z92D3O", className: "float-start w-100 p-0 m-0 rounded-circle o3654i", type: "button", onClick: this.Target },
                //Element
                React.createElement( Icon, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Call;