//Install
import React, { Component } from "react";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 c1n3l1" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                    //Element
                    "Facebook"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                    //Element
                    "Instagram"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "https://www.youtube.com/@ersahgida" },
                    //Element
                    "YouTube"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Submenu;