//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Form from "../../Content/Form";
//Install
import Headline from "../../Content/Headline";
//Install
import Location from "../../Content/Location";

//Element
class Default extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Erşah Gıda : Bilgilendirme Talebi - Ersahgida.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = ""
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 nf9678" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 jp1h55" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Location, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Form, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;