//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Product extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://media.ersahgida.com.tr/product/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-5 m-0 mt-5 t72040", href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 us10w5" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "201", height: "201" } )
            ),
            //Element
            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 lh-1 text-center do29m3" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Product.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Product;