//Install
import React, { Component } from "react";

//Element
class SET extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 position-relative t70p35" },
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 x429n0", href: "/bilgilendirme-talebi" },
                //Element
                "Bilgilendirme Talebi"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;