//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../Media/Icon/009.svg";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "float-start w-100 p-0 pt-2 m-0 a66710" },
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/" },
                    //Element
                    "Anasayfa",
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/kurumsal" },
                    //Element
                    "Kurumsal",
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/markalar" },
                    //Element
                    "Markalar",
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/urunler" },
                    //Element
                    "Ürünler",
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/galeri" },
                    //Element
                    "Galeri",
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-100" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/hemen-ulasin" },
                    //Element
                    "İletişim Bilgileri",
                    //Element
                    React.createElement( Icon, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Submenu;