//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Media from "../../Content/Media";
//Install
import Headline from "../../Content/Headline";
//Install
import Coop from "../../Shortcode/Coop/Default";
//Install
import Slaughter from "../../Shortcode/Slaughter/Default";

//Element
class Default extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Erşah Gıda : Kurumsal - Ersahgida.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = ""
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 nf9678" },
            //Element
            React.createElement( "section", { id: "Corporation_subPage", className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { style: { height: "auto" }, className: "float-start w-100 p-0 m-0 mt-5 ca762m" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative a02y3m" },
                                    //Element
                                    React.createElement( "iframe", { src: "https://www.youtube.com/embed/d9meSwnBGDQ?si=CHmcrvhz-PQKEUOQ", width: "560", height: "315" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 mt-4 position-relative a02y3m" },
                                    //Element
                                    React.createElement( "iframe", { src: "https://www.youtube.com/embed/C_aIa7ndRCg?si=hn4ret3kokdjz_P-", width: "560", height: "315" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-4 m-0 mt-5 n873nh" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 u2v138" },
                                    //Element
                                    "Kurumsal"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 b99gf4" },
                                    //Element
                                    "Doğanın bize sunduğu en değerli nimetlerle sizlere en kaliteli ürünleri sunmak için çiftliklerimizde titizlikle çalışıyoruz. Doğallık, lezzet ve sağlık bizim için önceliklidir."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 b99gf4" },
                                    //Element
                                    "Ürünlerimiz, günlük sütten elde edilen taze peynirlerden, geleneksel yöntemlerle işlenen enfes tereyağlarına, sağlıklı ve lezzetli et ürünlerine kadar geniş bir yelpazede sunulmaktadır."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 b99gf4" },
                                    //Element
                                    "Misyonumuz, sizlere en sağlıklı, lezzetli ve en doğal ürünleri sunarken, çevreye duyarlılık ve sürdürülebilirlik ilkelerini ön planda tutmaktır."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 b99gf4" },
                                    //Element
                                    "Siz değerli müşterilerimizin memnuniyeti, bizim için en büyük önceliktir. Ürünlerimizle ilgili herhangi bir sorunuz veya talebiniz varsa, bizimle iletişime geçmekten çekinmeyin."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 b99gf4" },
                                    //Element
                                    "Sağlıklı ve lezzetli bir yaşam için bizi tercih ettiğiniz için teşekkür ederiz."
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 ps-2 pe-2 m-0 o1k677" },
                                //Element
                                React.createElement( Coop, { ID: "8", Title: "Kümes" } ),
                                //Element
                                React.createElement( Slaughter, { ID: "3", Title: "Kesimhane" } )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;