//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "swiper/css/navigation";
//Install
import "swiper/css/pagination";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Corporation from "./Component/Tab/Corporation/Default";
//Install
import Brand from "./Component/Tab/Brand/Default";
//Install
import Product from "./Component/Tab/Product/Default";
//Install
import Media from "./Component/Tab/Media/Default";
//Install
import Contact from "./Component/Tab/Contact/Default";
//Install
import Information from "./Component/Tab/Information/Default";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/kurumsal" element={ <Corporation /> }></Route>
          <Route path="/markalar" element={ <Brand /> }></Route>
          <Route path="/urunler" element={ <Product /> }></Route>
          <Route path="/galeri" element={ <Media /> }></Route>
          <Route path="/bilgilendirme-talebi" element={ <Information /> }></Route>
          <Route path="/hemen-ulasin" element={ <Contact /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
