//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "nav", { className: "float-start w-100 p-0 m-0 i33x3z" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 x16w7v" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 gs816y" },
                            //Element
                            "Kurumsal"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qe2085" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/kurumsal" },
                                    //Element
                                    "Genel Bilgilendirme"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/markalar" },
                                    //Element
                                    "Markalar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/galeri" },
                                    //Element
                                    "Galeri"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/hemen-ulasin" },
                                    //Element
                                    "Hemen Ulasin"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 x16w7v" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 gs816y" },
                            //Element
                            "Ürünler"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qe2085" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Mozzarella"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Künefe"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Börek"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Tereyağı"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 x16w7v" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 opacity-0 gs816y" },
                            //Element
                            "Ürünler"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qe2085" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Piliç Baton Salam"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Piliç Çubuk Sosis"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Piliç Dilim Sucuk"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 x16w7v" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 opacity-0 gs816y" },
                            //Element
                            "Ürünler"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qe2085" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Dana Kangal Sucuk"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Dana Dilimli Sucuk"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/urunler" },
                                    //Element
                                    "Dana Baton Salam"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;