//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Open } from "../../Media/Icon/007.svg";
//Install
import { ReactComponent as Close } from "../../Media/Icon/008.svg";

//Element
class Hamburger extends Component {
    //Element
    Toggle = () => {
        //Element
        const Body       = document.getElementsByTagName( "body" )[0]
        //Element
        const Open       = document.getElementById( "Web_C62001" )
        //Element
        const Close      = document.getElementById( "Web_WV4668" )
        //Element
        const Navigation = document.getElementsByClassName( "ol691t" )[0]
        //Element
        Body.classList.toggle( "overflow-hidden" )
        //Element
        Open.classList.toggle( "d-none" )
        //Element
        Close.classList.toggle( "d-none" )
        //Element
        Navigation.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 m6v56f" },
            //Element
            React.createElement( "button", { id: "Web_C62001", name: "Web_C62001", className: "float-start w-100 p-0 m-0 rounded-circle pt638w", type: "button", onClick: this.Toggle },
                //Element
                React.createElement( Open, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_WV4668", name: "Web_WV4668", className: "float-start w-100 p-0 m-0 rounded-circle d-none s71u6w", type: "button", onClick: this.Toggle },
                //Element
                React.createElement( Close, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Hamburger;