//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class News extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Photo, Target } = this.props
        //Element
        let Source = "https://media.ersahgida.com.tr/news/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 pb-5 m-0 mt-5 mb-5 a724uj" },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mb-2 p1162k", style: { backgroundImage: "url("+ Image +")" } } ),
            //Element
            React.createElement( "h4", { className: "float-start w-auto p-0 ps-4 pe-4 m-0 mt-4 text-center uq243f" },
                //Element
                Title
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 ps-4 pe-4 m-0 mt-2 text-center w218j2" },
                //Element
                Summary
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
News.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default News;