//Install
import React, { Component } from "react";

//Element
class Location extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 yq3m85" },
            //Element
            React.createElement( "iframe", { width: "600", height: "450", src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.600735192002!2d28.795201175859887!3d41.03399027134705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5797c9b538b%3A0xa58d6f22441ac253!2sEr%C5%9Fah%20g%C4%B1da%20ve%20pazarlama!5e0!3m2!1str!2str!4v1714579923396!5m2!1str!2str", className: "border-0" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Location;