//Install
import React, { Component } from "react";

//Element
class Signature extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 n1490o" },
            //Element
            React.createElement( "a", { className: "float-end w-auto p-0 m-0 ia976y", href: "https://benozgurtosun.com", target: "_blank" },
                //Element
                "Özgür Tosun"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Signature;