//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Headline from "../../Content/Headline";
//Install
import Coop from "../../Shortcode/Coop/Default";
//Install
import Slaughter from "../../Shortcode/Slaughter/Default";

//Element
class Default extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Erşah Gıda : Hemen Ulasin - Ersahgida.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = ""
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 nf9678" },
            //Element
            React.createElement( "section", { id: "Contact_subPage", className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 c83208" },
                                //Element
                                React.createElement( "iframe", { width: "600", height: "450", src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.600735192002!2d28.795201175859887!3d41.03399027134705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5797c9b538b%3A0xa58d6f22441ac253!2sEr%C5%9Fah%20g%C4%B1da%20ve%20pazarlama!5e0!3m2!1str!2str!4v1714579923396!5m2!1str!2str", className: "float-start w-100 h-100 bg-transparent border-0" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-4 m-0 mt-5 n873nh" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 u2v138" },
                                    //Element
                                    "Hemen Ulasin"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 e07300" },
                                    //Element
                                    "Siz değerli müşterilerimizin memnuniyeti bizim için her zaman ön plandadır. Ürünlerimizle ilgili herhangi bir sorunuz veya talebiniz varsa, bizimle",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "iletişime geçmekten çekinmeyin."
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ui0216" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 y52o6y" },
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 y0914h" },
                                                    //Element
                                                    "Telefon"
                                                ),
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 k56l3c" },
                                                    //Element
                                                    "0551 119 69 33"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 y52o6y" },
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 y0914h" },
                                                    //Element
                                                    "E-Posta"
                                                ),
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 k56l3c" },
                                                    //Element
                                                    "info@ersahgida.com.tr"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 y52o6y" },
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 y0914h" },
                                                    //Element
                                                    "Konum"
                                                ),
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 k56l3c" },
                                                    //Element
                                                    "Halkalı Merkez, Çınarlıçeşme Sk. No: 2, 34100 Küçükçekmece/İstanbul"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 ps-2 pe-2 m-0 o1k677" },
                                //Element
                                React.createElement( Coop, { ID: "8", Title: "Kümes" } ),
                                //Element
                                React.createElement( Slaughter, { ID: "3", Title: "Kesimhane" } )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;