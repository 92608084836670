//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Form extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 q66z1w" },
            //Element
            React.createElement( "form", { id: "Web_Form", name: "Web_Form", className: "float-start w-100 p-5 m-0 mt-2 m545xs", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 m8f94f" },
                            //Element
                            React.createElement( "input", { id: "Web_FormName", name: "Web_FormName", className: "float-start w-100 p-0 ps-4 pe-4 m-0 t691jv", placeholder: "Ad", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 m8f94f" },
                            //Element
                            React.createElement( "input", { id: "Web_FormSurname", name: "Web_FormSurname", className: "float-start w-100 p-0 ps-4 pe-4 m-0 t691jv", placeholder: "Soyad", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 m8f94f" },
                            //Element
                            React.createElement( "input", { id: "Web_FormEmail", name: "Web_FormEmail", className: "float-start w-100 p-0 ps-4 pe-4 m-0 t691jv", placeholder: "e-Posta", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 m8f94f" },
                            //Element
                            React.createElement( "input", { id: "Web_FormPhone", name: "Web_FormPhone", className: "float-start w-100 p-0 ps-4 pe-4 m-0 t691jv", placeholder: "Telefon", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 m8f94f" },
                            //Element
                            React.createElement( "textarea", { id: "Web_FormMessage", name: "Web_FormMessage", className: "float-start w-100 p-0 ps-4 pe-4 pt-3 pb-3 m-0 ux15p1", placeholder: "Mesaj", rows: "5", cols: "30", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 m8f94f" },
                            //Element
                            React.createElement( "button", { id: "Web_FormSubmit", name: "Web_FormSubmit", className: "float-start w-100 p-0 m-0 j922wq", type: "button" },
                                //Element
                                "Hemen Gönder"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d-none m8f94f" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 text-center lh-1 c745qr" },
                                //Element
                                "Tebrikler Başarı İle Gönderildi."
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Form;