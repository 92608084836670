//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../Media/Icon/001.svg";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 pe-4 m-0 ga171l" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                    //Element
                    "Anasayfa"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/kurumsal" },
                    //Element
                    "Kurumsal"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/markalar" },
                    //Element
                    "Markalar"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 ps-3 pe-3 pt-1 pb-2 m-0 position-absolute d9s93k" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/markalar" },
                            //Element
                            "Oret",
                            //Element
                            React.createElement( Icon, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/markalar" },
                            //Element
                            "Şahköy Tavuğu",
                            //Element
                            React.createElement( Icon, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/markalar" },
                            //Element
                            "Zeyno'nun Çiftliği",
                            //Element
                            React.createElement( Icon, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/urunler" },
                    //Element
                    "Ürünler"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/galeri" },
                    //Element
                    "Galeri"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/hemen-ulasin" },
                    //Element
                    "İletişim"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;