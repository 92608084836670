//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 ub750q" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-5 pt-0 pb-0 m-0 bg-transparent sa338r" },
                //Element
                React.createElement( "h3", { className: "float-start w-auto p-0 m-0 h7a62f" },
                    //Element
                    ID
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-1 qxn175" },
                    //Element
                    Title
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Default;