//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Brand extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 a89t91", onClick: this.Home },
            //Element
            React.createElement( "img", { alt: Title, title: Title, src: "https://media.ersahgida.com.tr/brand/006.png", width: "80", height: "79" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Brand.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Brand;