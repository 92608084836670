//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Headline extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Video, Target } = this.props
        //Element
        let Source = "https://ersah.s3.eu-north-1.amazonaws.com/video/"
        //Element
        let Media  = Source + Video
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 q1i0b4" },
            //Element
            React.createElement( "video", { className: "float-start w-100 h-auto", playsInline: "playsinline", src: Media, controls: false, loop: true, autoPlay: true, muted: "muted" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Headline.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Video  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Headline;